import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { formDataConverter } from '../../_helpers/formDataConverter';
import {
  closeModalReducer,
  openDeleteModalReducer,
  // eslint-disable-next-line prettier/prettier
  openEditModalReducer,
} from '../../components/modal/modalSlice';
import {
  addActivity,
  getActivityById,
  getAllActivities,
  getUsersList,
  postOrderChangeActivity,
  removeActivity,
  removeRepeatingActivity,
  // eslint-disable-next-line prettier/prettier
  updateActivity,
} from '../../services/activitiesService';
import { getImageDetails } from '../../services/amenitiesService';
import { getImageId } from '../../services/facilityService';
import { IImageDetails } from '../amenitiesPage/amenitiesInterface';
import {
  IActivitiesFilter,
  IActivitiesFilterPayload,
  IActivity,
  IActivityData,
  IActivityOrderPayload,
  IActivityPaginate,
  IDeleteActivityPayload,
  // eslint-disable-next-line prettier/prettier
  IDeleteEditActivityPayload,
  IGuestUserData,
  IRemoveRepeatingActivityPayload,
} from './activitiesInterface';
import { getActivitiesRequestParams } from './activityParams';
import { addActivityManupulator, filterChange } from './utilityFunctions';

export interface IActivities {
  isProcessingAllActivity: boolean;
  isProcessingActivity: boolean;
  isAddingAcitity: boolean;
  addedNewActivity: boolean;
  isUpdatingActivity: boolean;
  updatedExistingActivity: boolean;
  isDeletingActivity: boolean;
  deletedActivity: boolean;
  filter: IActivitiesFilter;
  activity: IActivity;
  activityList: IActivity[];
  activityPaginate: IActivityPaginate;
  isAddingPoster: boolean;
  isFetchingImageDetails: boolean;
  imageDetails: IImageDetails;
  isProcessingEvent: boolean;
  event: IActivity;
  isFetchingGuests: boolean;
  guestUsersData: IGuestUserData[];
}
const initialState: IActivities = {
  isProcessingAllActivity: false,
  isProcessingActivity: false,
  isAddingAcitity: false,
  addedNewActivity: false,
  isUpdatingActivity: false,
  updatedExistingActivity: false,
  isDeletingActivity: false,
  deletedActivity: false,
  filter: {
    sort: 'position,Asc',
    page: 0,
    size: 20,
  },
  activity: {} as IActivity,
  activityList: [],
  activityPaginate: {
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 0,
    number: 0,
  },
  isAddingPoster: false,
  isFetchingImageDetails: false,
  imageDetails: {} as IImageDetails,
  isProcessingEvent: false,
  event: {} as IActivity,
  isFetchingGuests: false,
  guestUsersData: [],
};

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    resetActivityState: () => {
      return initialState;
    },
    addFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    activityFilterPageReset: (state) => {
      state.filter.page = 0;
    },
    addActivityTranslatedData: (state, action) => {
      state.activityList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllActivities.pending, (state) => {
        state.isProcessingAllActivity = true;
      })
      .addCase(fetchAllActivities.fulfilled, (state, action) => {
        state.isProcessingAllActivity = false;
        state.activityList = action.payload?.data[0]?.data;
        state.activityPaginate = action.payload?.data[0];
      })
      .addCase(fetchAllActivities.rejected, (state) => {
        state.isProcessingAllActivity = false;
      })
      .addCase(fetchActivityById.pending, (state) => {
        state.isProcessingActivity = true;
      })
      .addCase(fetchActivityById.fulfilled, (state, action) => {
        state.isProcessingActivity = false;
        state.activity = action.payload?.data[0];
      })
      .addCase(fetchActivityById.rejected, (state) => {
        state.isProcessingActivity = false;
      })
      .addCase(fetchEventById.pending, (state) => {
        state.isProcessingEvent = true;
      })
      .addCase(fetchEventById.fulfilled, (state, action) => {
        state.isProcessingEvent = false;
        state.event = action.payload?.data[0];
      })
      .addCase(fetchEventById.rejected, (state, action: PayloadAction<any>) => {
        state.isProcessingEvent = false;
        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })
      .addCase(addNewActivity.pending, (state) => {
        state.isAddingAcitity = true;
        state.addedNewActivity = false;
      })
      .addCase(addNewActivity.fulfilled, (state, action) => {
        state.isAddingAcitity = false;
        state.addedNewActivity = true;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(addNewActivity.rejected, (state, action: PayloadAction<any>) => {
        state.isAddingAcitity = false;
        state.addedNewActivity = false;
        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })
      .addCase(fetchActivityImageId_Add.pending, (state) => {
        state.isAddingPoster = true;
      })
      .addCase(fetchActivityImageId_Add.fulfilled, (state) => {
        state.isAddingPoster = false;
      })
      .addCase(fetchActivityImageId_Add.rejected, (state) => {
        state.isAddingPoster = false;
      })
      .addCase(updateSelectedActivity.pending, (state) => {
        state.isUpdatingActivity = true;
        // state.updatedExistingActivity = false;
      })
      .addCase(updateSelectedActivity.fulfilled, (state, action) => {
        state.isUpdatingActivity = false;
        // state.updatedExistingActivity = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        updateSelectedActivity.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingActivity = false;
          // state.updatedExistingActivity = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchActivityImageId_Update.pending, (state) => {
        state.isAddingPoster = true;
      })
      .addCase(fetchActivityImageId_Update.fulfilled, (state) => {
        state.isAddingPoster = false;
      })
      .addCase(fetchActivityImageId_Update.rejected, (state) => {
        state.isAddingPoster = false;
      })
      .addCase(deleteSelectedActivity.pending, (state) => {
        state.isDeletingActivity = true;
        state.deletedActivity = false;
      })
      .addCase(deleteSelectedActivity.fulfilled, (state, action) => {
        state.isDeletingActivity = false;
        state.deletedActivity = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        deleteSelectedActivity.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingActivity = false;
          state.deletedActivity = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteRepeatingActivity.pending, (state) => {
        state.isDeletingActivity = true;
        state.deletedActivity = false;
      })
      .addCase(deleteRepeatingActivity.fulfilled, (state, action) => {
        state.isDeletingActivity = false;
        state.deletedActivity = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        deleteRepeatingActivity.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingActivity = false;
          state.deletedActivity = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchActivityImageDetails.pending, (state) => {
        state.isFetchingImageDetails = true;
        state.imageDetails = { id: '', originalName: '' };
      })
      .addCase(fetchActivityImageDetails.fulfilled, (state, action) => {
        state.isFetchingImageDetails = false;
        state.imageDetails = action.payload?.data[0];
      })
      .addCase(fetchActivityImageDetails.rejected, (state) => {
        state.isFetchingImageDetails = false;
      })
      .addCase(fetchEventCheckedInUsers.pending, (state) => {
        state.isFetchingGuests = true;
      })
      .addCase(fetchEventCheckedInUsers.fulfilled, (state, action) => {
        state.isFetchingGuests = false;
        state.guestUsersData = action.payload?.data[0]?.users;
      })
      .addCase(fetchEventCheckedInUsers.rejected, (state) => {
        state.isFetchingGuests = false;
      });
  },
});

export const fetchAllActivities = createAsyncThunk(
  'activities/fetchAllActivities',
  async (data: IActivitiesFilterPayload, { rejectWithValue }) => {
    try {
      const params = getActivitiesRequestParams(
        data.sort,
        data.page,
        data.size,
        data.facilityIds
      );
      const response = await getAllActivities({ ...params, date: data.date });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchActivityImageDetails = createAsyncThunk(
  'activities/fetchActivityImageDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImageDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchActivityById = createAsyncThunk(
  'activities/fetchActivityById',
  async (data: IDeleteEditActivityPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await getActivityById(data?.id);
      let particularDatesArr: string[] = [];
      if (response?.data[0]?.posterImageId) {
        dispatch(fetchActivityImageDetails(response?.data[0]?.posterImageId));
      }
      if (data?.for === 'delete') {
        dispatch(openDeleteModalReducer());
      } else if (data?.for === 'edit') {
        dispatch(openEditModalReducer());
      }
      if (
        !response?.data[0]?.repeatable &&
        response?.data[0]?.particularDates?.length != 0
      ) {
        particularDatesArr = response?.data[0]?.particularDates?.map(
          (item: string) => moment(item, 'YYYY-MM-DD').valueOf()
        );
        const updatedValue = response?.data?.map((item: IActivity) => {
          return {
            ...item,
            particularDates: particularDatesArr,
          };
        });
        return { ...response, data: updatedValue };
      }
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const fetchEventById = createAsyncThunk(
  'activities/fetchEventById',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await getActivityById(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);
export const addNewActivity = createAsyncThunk(
  'activities/addNewActivity',
  async (data: IActivityData, { rejectWithValue, getState, dispatch }) => {
    const { activities } = getState() as { activities: IActivities };
    try {
      const response = await addActivity(data);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllActivities(
          filterChange(activities.filter, data?.facilityId, data.date)
        )
      );
      console.log(response);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchActivityImageId_Add = createAsyncThunk(
  'activities/fetchActivityImageId_Add',
  async (data: IActivityData, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data?.image));
      const response = dispatch(
        addNewActivity(
          addActivityManupulator(data, imageResponse?.data[0]?.id, data?.date)
        )
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchActivityImageId_Update = createAsyncThunk(
  'activities/fetchActivityImageId_Update',
  async (data: IActivityData, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data?.image));
      const response = dispatch(
        updateSelectedActivity(
          addActivityManupulator(data, imageResponse?.data[0]?.id, data?.date)
        )
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const updateSelectedActivity = createAsyncThunk(
  'activities/updateSelectedActivity',
  async (data: IActivityData, { rejectWithValue, getState, dispatch }) => {
    const { activities } = getState() as { activities: IActivities };
    try {
      const response = await updateActivity(data);
      await dispatch(addActivityTranslatedData([]));
      await dispatch(addFilterPage(0));
      dispatch(closeModalReducer());
      dispatch(
        fetchAllActivities(
          filterChange(activities.filter, data?.facilityId, data?.date)
        )
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const deleteSelectedActivity = createAsyncThunk(
  'activities/deleteSelectedActivity',
  async (
    data: IDeleteActivityPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { activities } = getState() as { activities: IActivities };
    try {
      const response = await removeActivity(
        data?.id,
        data.repeatOption,
        data?.date
      );
      await dispatch(addActivityTranslatedData([]));
      await dispatch(addFilterPage(0));

      dispatch(closeModalReducer());
      dispatch(
        fetchAllActivities(
          filterChange(activities.filter, data?.facilityId, data.date)
        )
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteRepeatingActivity = createAsyncThunk(
  'activities/deleteRepeatingActivity',
  async (
    data: IRemoveRepeatingActivityPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { activities } = getState() as { activities: IActivities };
    try {
      const response = await removeRepeatingActivity(data);
      dispatch(addActivityTranslatedData([]));
      dispatch(addFilterPage(0));
      dispatch(closeModalReducer());
      dispatch(
        fetchAllActivities(
          filterChange(activities.filter, data?.facilityId, data.date)
        )
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchEventCheckedInUsers = createAsyncThunk(
  'activities/fetchEventCheckedInUser',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getUsersList(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const changeActivityOrder = createAsyncThunk(
  'activities/changeActivityOrder',
  async (data: IActivityOrderPayload, { rejectWithValue }) => {
    try {
      const response = await postOrderChangeActivity(data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const {
  resetActivityState,
  addFilterPage,
  activityFilterPageReset,
  addActivityTranslatedData,
} = activitiesSlice.actions;
export const activitiesReducer = activitiesSlice.reducer;
